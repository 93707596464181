import React, { createContext, useContext, useState, useCallback } from 'react';
import useWebSocketConnection from '../handlers/handleWebSocketConnection.js';
import handleIncomingMessage from '../handlers/handleIncomingMessage.js';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ url, children }) => {
  const [messages, setMessages] = useState([]);

  const processIncomingMessage = useCallback((data) => {
    handleIncomingMessage(data, (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
  }, []);

  useWebSocketConnection(url, processIncomingMessage);

  return (
    <WebSocketContext.Provider value={messages}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

