import React, { useState, useEffect } from "react";
import Message, { formatMessageDetails } from "./Message.js";
import '../css/MessageGroup.css';

const MessageGroup = ({ quoteRequestID, messages = [] }) => {
  const [isOpen, setIsOpen] = useState(false); // Default to closed
  const [latestMessageContent, setLatestMessageContent] = useState("");
  const [latestMessageType, setLatestMessageType] = useState("");

  useEffect(() => {
    if (messages.length > 0) {
      const message = messages[messages.length - 1]; // Get the latest message
      const messageContent = formatMessageDetails(message);
      setLatestMessageContent(messageContent);
      setLatestMessageType(message.type);
    }
  }, [messages]);

  return (
    <div>
      <div
        className="message-group-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{isOpen ? "−" : "+"}</span>
        {`${quoteRequestID}: `}
        <span className={`message ${latestMessageType}`}>
          {latestMessageContent}
        </span>
      </div>
      {isOpen && (
        <div>
          {messages.map((message, index) => (
            <Message key={index} message={message} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MessageGroup;

