import React from "react";

export const formatMessageDetails = (message) => {
  const formatDecimal = (decimal, decimals = 2) => {
    if (decimal && typeof decimal === 'object' && 'mantissa' in decimal && 'exponent' in decimal) {
      return (decimal.mantissa * Math.pow(10, decimal.exponent)).toFixed(decimals);
    }
    return null;
  };

  let details = `${message.type}`;

  for (const key in message) {
    switch (key) {
      case 'amount':
        details += ` amount: ${formatDecimal(message.amount)}`;
        break;
      case 'currency':
        details += `, Currency: ${message.currency}`;
        break;
      case 'saleCurrency':
        details += `, Sale Currency: ${message.saleCurrency}`;
        break;
      case 'side':
        details += `, Side: ${message.side}`;
        break;
      case 'symbol':
        details += `, Symbol: ${message.symbol}`;
        break;
      case 'deliveryDate':
        details += `, Delivery Date: ${message.deliveryDate}`;
        break;
      case 'transactTime':
        details += `, Transact Time: ${message.transactTime}`;
        break;
      case 'quoteRequestID':
        details += `, Quote Req ID: ${message.quoteRequestID}`;
        break;
      case 'quoteID':
        details += `, Quote ID: ${message.quoteID}`;
        break;
      case 'dealRequestID':
        details += `, Deal Req ID: ${message.dealRequestID}`;
        break;
      case 'dealID':
        details += `, Deal ID: ${message.dealID}`;
        break;
      case 'message':
        details += `, Message: ${message.message}`;
        break;
      case 'fxRate':
        details += `, FX Rate: ${formatDecimal(message.fxRate, 5)}`;
        break;
      case 'secondaryAmount':
        details += `, Amount2: ${formatDecimal(message.secondaryAmount)}`;
        break;
      case 'secondaryCurrency':
        details += `, Currency2: ${message.secondaryCurrency}`;
        break;
      case 'currencyOwned':
        details += `, Currency Owned: ${message.currencyOwned}`;
        break;
      case 'clientID':
        details += `, Client ID: ${message.clientID}`;
        break;
      case 'applicationName':
        details += `, ${message.applicationName}`;
        break;
      case 'instanceId':
        details += `, Instance: ${message.instanceId}`;
        break;
      case 'environment':
        details += `, Env: ${message.environment}`;
        break;
      case 'messageType':
        details += `, Message: ${message.messageType}`;
        break;
      case 'timestamp':
        details += `, Time: ${message.timestamp}`;
        break;
      case 'detailedMessage':
        details += `, Message: ${message.detailedMessage}`;
        break;
      case 'hostInfo':
        details += `, Host: ${message.hostInfo}`;
        break;
      default:
        break;
    }
  }

  return details;
};

const Message = ({ message }) => {
  return (
    <div className={`message ${message.type}`} style={{ padding: '1px', marginBottom: '1px' }}>
      {formatMessageDetails(message)}
    </div>
  );
};

export default Message;

