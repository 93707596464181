import MessageHeaderDecoder from '../aeron/js/MessageHeaderDecoder.js';
import QuoteRequestDecoder from '../aeron/js/QuoteRequestDecoder.js';
import QuoteDecoder from '../aeron/js/QuoteDecoder.js';
import DealRequestDecoder from '../aeron/js/DealRequestDecoder.js';
import ExecutionReportDecoder from '../aeron/js/ExecutionReportDecoder.js';
import ErrorDecoder from '../aeron/js/ErrorDecoder.js';
import AdminDecoder from '../aeron/js/AdminDecoder.js';

const handleIncomingMessage = (data, setMessage) => {
    console.log('Incoming data: ', data);

    if (!(data instanceof ArrayBuffer)) {
        console.error('Data is not an ArrayBuffer:', data);
        return;
    }

    try {
        const headerDecoder = new MessageHeaderDecoder();
        headerDecoder.wrap(data, 0);

        const schemaId = headerDecoder.schemaId();
        const templateId = headerDecoder.templateId();

        let decodedData;

        switch (schemaId) {
            case 1: // Messages schema
                decodedData = decodeMessagesSchema(templateId, data);
                break;
            case 3: // Admin schema
                decodedData = decodeAdminSchema(templateId, data);
                break;
            default:
                console.error('Unknown schemaId:', schemaId);
                return;
        }

        if (decodedData) {
            setMessage(decodedData);
        }

    } catch (error) {
        console.error('Error processing message:', error);
    }
};

export default handleIncomingMessage;

const decodeMessagesSchema = (templateId, data) => {
    switch (templateId) {
        case 1: { // DealRequest
            const decoder = new DealRequestDecoder();
            decoder.wrap(data, MessageHeaderDecoder.ENCODED_LENGTH);
            return {
                type: "dealRequest",
                amount: decoder.decodeamount(),
                currency: decoder.currency(),
                side: decoder.side().replace(/\0/g, ''),
                symbol: decoder.symbol(),
                deliveryDate: decoder.deliveryDate(),
                transactTime: decoder.transactTime(),
                quoteRequestID: decoder.quoteRequestID().replace(/\0/g, ''),
                quoteID: decoder.quoteID().replace(/\0/g, ''),
                dealRequestID: decoder.dealRequestID().replace(/\0/g, ''),
                fxRate: decoder.decodefxRate(),
                secondaryAmount: decoder.decodesecondaryAmount(),
                clientID: decoder.clientID()
            };
        }
        case 2: { // Execution Report
            const decoder = new ExecutionReportDecoder();
            decoder.wrap(data, MessageHeaderDecoder.ENCODED_LENGTH);
            return {
                type: "executionReport",
                amount: decoder.decodeamount(),
                currency: decoder.currency(),
                secondaryAmount: decoder.decodesecondaryAmount(),
                secondaryCurrency: decoder.secondaryCurrency(),
                side: decoder.side().replace(/\0/g, ''),
                symbol: decoder.symbol(),
                deliveryDate: decoder.deliveryDate(),
                transactTime: decoder.transactTime(),
                quoteRequestID: decoder.quoteRequestID().replace(/\0/g, ''),
                quoteID: decoder.quoteID().replace(/\0/g, ''),
                dealRequestID: decoder.dealRequestID().replace(/\0/g, ''),
                dealID: decoder.dealID().replace(/\0/g, ''),
                fxRate: decoder.decodefxRate(),
                clientID: decoder.clientID()
            };
        }
        case 3: { // QuoteRequest
            const decoder = new QuoteRequestDecoder();
            decoder.wrap(data, MessageHeaderDecoder.ENCODED_LENGTH);
            return {
                type: "quoteRequest",
                amount: decoder.decodeamount(),
                saleCurrency: decoder.saleCurrency(),
                side: decoder.side().replace(/\0/g, ''),
                symbol: decoder.symbol(),
                deliveryDate: decoder.deliveryDate(),
                transactTime: decoder.transactTime(),
                quoteRequestID: decoder.quoteRequestID().replace(/\0/g, ''),
                currencyOwned: decoder.currencyOwned(),
                clientID: decoder.clientID()
            };
        }

        case 4: { // Quote
            const decoder = new QuoteDecoder();
            decoder.wrap(data, MessageHeaderDecoder.ENCODED_LENGTH);
            return  {
                type: "quote",
                amount: decoder.decodeamount(),
                currency: decoder.currency(),
                side: decoder.side().replace(/\0/g, ''),
                symbol: decoder.symbol(),
                transactTime: decoder.transactTime(),
                quoteID: decoder.quoteID().replace(/\0/g, ''),
                quoteRequestID: decoder.quoteRequestID().replace(/\0/g, ''),
                fxRate: decoder.decodefxRate(),
                secondaryAmount: decoder.decodesecondaryAmount(),
                clientID: decoder.clientID()
            };
        }

        case 5: { // Error
            const decoder = new ErrorDecoder();
            decoder.wrap(data, MessageHeaderDecoder.ENCODED_LENGTH);
            return {
                type: "error",
                amount: decoder.decodeamount(),
                currency: decoder.currency(),
                side: decoder.side().replace(/\0/g, ''),
                symbol: decoder.symbol(),
                deliveryDate: decoder.deliveryDate(),
                transactTime: decoder.transactTime(),
                quoteRequestID: decoder.quoteRequestID().replace(/\0/g, ''),
                quoteID: decoder.quoteID().replace(/\0/g, ''),
                dealRequestID: decoder.dealRequestID().replace(/\0/g, ''),
                dealID: decoder.dealID().replace(/\0/g, ''),
                fxRate: decoder.decodefxRate(),
                clientID: decoder.clientID(),
                message: decoder.message().replace(/\0/g, '')
            };
        }

        default:
            console.error('Unknown message templateId:', templateId);
            return null;
    }
};

const decodeAdminSchema = (templateId, data) => {
    if (templateId === 1) { // Admin message
        const decoder = new AdminDecoder();
        decoder.wrap(data, MessageHeaderDecoder.ENCODED_LENGTH);
        return {
            type: "admin",
            applicationName: decoder.applicationName().replace(/\0/g, ''),
            instanceId: decoder.instanceId().replace(/\0/g, ''),
            environment: decoder.environment().replace(/\0/g, ''),
            messageType: decoder.messageType().replace(/\0/g, ''),
            timestamp: decoder.timestamp(),
            detailedMessage: decoder.detailedMessage().replace(/\0/g, ''),
            hostInfo: decoder.hostInfo().replace(/\0/g, '')
        };
    } else {
        console.error('Unknown Admin templateId:', templateId);
        return null;
    }
};
