import React, { useEffect, useState } from "react";
import { useWebSocket } from "../contexts/WebSocketContext.js";
import MessageGroup from "./MessageGroup.js";
import '../css/MessageMonitor.css';

const MessageMonitor = ({ amplifyUsername, kycComplete }) => {
  const messages = useWebSocket(); // Get WebSocket messages from context
  const [messageGroups, setMessageGroups] = useState({});

  console.log('amplifyUsername:', amplifyUsername);
  console.log('kycComplete:', kycComplete);

  useEffect(() => {
    if (messages.length > 0) {
      const groups = messages.reduce((acc, message) => {
        if (message && message.quoteRequestID) {
          const { quoteRequestID } = message;
          if (!acc[quoteRequestID]) {
            acc[quoteRequestID] = [];
          }
          acc[quoteRequestID].push(message);
        } else {
          const { timestamp } = message;
          if (!acc[timestamp]) {
            acc[timestamp] = [];
          }
          acc[timestamp].push(message);
        }
        return acc;
      }, {});

      setMessageGroups(groups);
    }
  }, [messages]); // Update whenever messages change

  return (
    <div className="message-monitor-container">
      {Object.keys(messageGroups).reverse().map((quoteRequestID) => (
        <MessageGroup
          key={quoteRequestID}
          quoteRequestID={quoteRequestID}
          messages={messageGroups[quoteRequestID]}
        />
      ))}
    </div>
  );
};

export default MessageMonitor;


